/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

html,
body {
  font-family: var(--ion-font-family);
}

video.cameraView {
  width: 100% !important;
  height: 100% !important;
  max-height: 100% !important;
  min-height: 100% !important;
  object-fit: cover !important;
}

ion-toolbar {
  --background: var(--ion-color-white, #ffffff);
  box-shadow: 0px 4px 4px rgba(200, 200, 200, 0.12);
  height: 64px;

  display: flex;
  align-items: center;
}

ion-content {
  --background: var(--ion-color-page, #f9f9f9);
}

.custom-title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 64px 0px 32px;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 17px;
  font-weight: 600;
  color: var(--ion-color-black, #000000);
}

.item .sc-ion-label-md-h,
.item .sc-ion-label-ios-h {
  white-space: normal !important;
}

.ewa-footer-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
}

.ewa-footer {
  font-style: normal;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  color: var(--ion-color-footer, #d6d2d2);
  margin: 0;
}

.ewa-footer-link {
  text-align: center;
}

.verification-title-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: env(safe-area-inset-top);
  margin: 20px;

  .verification-page-title {
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 23px;
    color: var(--ion-color-black, #000000);
    text-align: center;
  }
}

ion-popover {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.3);

  .popover-wrapper {
    .popover-content {
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }
}

.cancel-verification-wrap {
  --width: 313px;

  padding: 20px;
  display: flex;
  flex-direction: column;

  .header {
    .warning-icon {
      height: 33px;
      width: 33px;
    }
  }

  .body {
    .title {
      font-style: normal;
      font-weight: 900;
      font-size: 19px;
      line-height: 24px;
      color: var(--ion-color-black, #000);
    }

    .description {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: var(--ion-color-black, #000);
    }

    .hint {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: rgba(21, 25, 32, 0.5);
    }
  }

  .footer {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 24px auto auto auto;

    .close,
    .cancel {
      flex-grow: 1;
      height: 48px;
      text-align: center;
    }

    .close {
      border: 1px solid rgba(86, 103, 137, 0.26);
      box-sizing: border-box;
      border-radius: 4px;
      margin-right: 4px;

      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      background: transparent;

      color: rgba(21, 25, 32, 0.5);
    }

    .cancel {
      background: var(--ion-color-red, #eb5757);
      border-radius: 4px;
      margin-left: 4px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      color: var(--ion-color-white, #ffffff);
    }
  }
}

.logout-confirmation-popup {
  --width: 313px;

  padding: 20px;
  display: flex;
  flex-direction: column;

  .header {
    .warning-icon {
      height: 33px;
      width: 33px;
    }
  }

  .body {
    .title {
      font-style: normal;
      font-weight: 900;
      font-size: 19px;
      line-height: 24px;
      color: var(--ion-color-black, #000);
    }

    .description {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: var(--ion-color-black, #000);
    }
  }

  .footer {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 24px auto auto auto;

    .close,
    .logout {
      flex-grow: 1;
      flex-basis: 0;
      height: 48px;
      text-align: center;
    }

    .close {
      border: 1px solid rgba(86, 103, 137, 0.26);
      box-sizing: border-box;
      border-radius: 4px;
      margin-right: 4px;

      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      background: transparent;

      color: rgba(21, 25, 32, 0.5);
    }

    .logout {
      background: var(--ion-color-orange, #f2994a);
      border-radius: 4px;
      margin-left: 4px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      color: var(--ion-color-white, #ffffff);
    }
  }
}

.deletion-restriction-popup {
  --width: 300px;

  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .header {
    width: 64px;
    height: 64px;
    border-radius: 500px;
    flex: none;
    margin: 16px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
        0deg,
        rgba(240, 48, 22, 0.06),
        rgba(240, 48, 22, 0.06)
      ),
      #ffffff;

    .delete-icon {
      font-size: 24px;
      color: var(--ion-status-color-error, #ff0000);
    }
  }

  .body {
    .title {
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 24px;
      color: var(--ion-color-black, #000);
      text-align: center;
    }

    .description {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: var(--ion-color-required, #666666);
      text-align: center;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 24px auto auto auto;

    .close {
      width: 100%;
      margin: 0px auto;
      height: 48px;
      box-sizing: border-box;
      border-radius: 4px;
      margin-right: 4px;

      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      text-align: center;

      --background: var(--ion-color-retake, #f2f2f2);
      --color: var(--ion-color-grey, #333333);

      --background-activated: var(--ion-stepper-prev, #e9e9e9);
      --color-activated: var(--ion-color-black, #000000);
    }
  }
}
.disable-notification-popup,
.account-deteted-popup,
.account-exists-popup,
.no-account-popup,
.verification-failed-popup,
.metro-confirmation-popup,
.account-does-not-exist-popup {
  --width: 300px;

  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .header {
    width: 64px;
    height: 64px;
    border-radius: 500px;
    flex: none;
    margin: 16px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
        0deg,
        rgba(240, 48, 22, 0.06),
        rgba(240, 48, 22, 0.06)
      ),
      #ffffff;

    .warning-icon {
      font-size: 40px;
      color: var(--ion-status-color-error, #ff0000);
    }

    &.alert {
      background: linear-gradient(
          0deg,
          rgba(255, 204, 62, 0.1) 0%,
          rgba(255, 204, 62, 0.1) 100%
        ),
        #fff;

      .warning-icon {
        color: var(--ion-status-color-warning, #ffcc3e);
      }
    }
  }

  .body {
    .title {
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 24px;
      color: var(--ion-color-black, #000);
      text-align: center;
    }

    .description {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: var(--ion-color-required, #666666);
      text-align: center;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 24px auto auto auto;
    gap: 12px;

    ion-button {
      flex-grow: 1;
      flex-basis: 0;
      margin: 0px;
      height: 48px;
      box-sizing: border-box;
      --border-radius: 4px;

      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      text-align: center;

      &.close,
      &.cancel {
        --background: var(--ion-color-retake, #f2f2f2);
        --color: var(--ion-color-grey, #333333);

        --background-activated: var(--ion-stepper-prev, #e9e9e9);
        --color-activated: var(--ion-color-black, #000000);
      }

      &.contact-us,
      &.get-help,
      &.confirm,
      &.action {
        --background: var(--ion-color-grey, #333333);
        --color: var(--ion-color-white, #ffffff);
        --background-activated: var(--ion-color-black, #000000);
      }
    }
  }
}

.chat-popup {
  --width: 313px;

  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 280px;

  &.line-chat {
    .chat-popup-icon {
      background: var(--ion-line-chat-color, #41c50a);
    }

    .chat-popup-btn {
      --background: var(--ion-line-chat-color, #41c5ß0a);
      --background-activated: var(--ion-line-chat-color-dark, #36a300);
    }

    .chat-popup-desc {
      span {
        font-weight: 600;
        color: var(--ion-color-green, #3bbf41);
      }
    }
  }

  &.facebook-chat {
    .chat-popup-icon {
      background: var(--ion-fb-chat-bg, #3b5998);
    }

    .chat-popup-desc {
      span {
        font-weight: 600;
        color: #4267b2;
      }
    }

    .chat-popup-btn {
      --background: #1877f2;
      --background-activated: var(--ion-fb-chat-bg, #3b5998);
    }
  }

  &.whatsapp-chat {
    .chat-popup-icon {
      background: var(--ion-whatsapp-chat-bg, #0d9f16);

      img {
        width: 32px;
        height: 32px;
      }
    }

    .chat-popup-btn {
      --background: var(--ion-whatsapp-chat-bg, #0d9f16);
      --background-activated: var(--ion-whatsapp-chat-bg-dark, #0a7f12);
    }

    .chat-popup-desc {
      span {
        font-weight: 600;
        color: var(--ion-whatsapp-chat-bg, #0d9f16);
      }
    }
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 500px;

    img {
      width: 48px;
      height: 48px;
    }
  }

  &-desc {
    width: 100%;
    margin: 24px auto 32px auto;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    color: var(--ion-color-black, #000000);
  }

  &-btn {
    width: 100%;
    margin: 0px auto;
    height: 52px;
    min-width: 140px;
    border-radius: 8px;
    --background: var(--ion-color-green, #3bbf41);

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    margin: 0px 0px 8px 0px;
    color: var(--ion-color-white, #ffffff);
  }
}

.reminder-modal-wrapper {
  --width: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 280px;
  box-sizing: border-box;

  .reminder-header {
    width: 100%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    gap: 6px;
    border-bottom: 1px solid var(--ion-color-footer);

    .reminder-title {
      width: 100%;
      margin: 0px auto;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;

      color: var(--ion-color-black, #000000);
    }

    .reminder-sub-title {
      font-style: normal;
      font-weight: 300;
      font-size: 13.5px;
      line-height: 17.5px;
      color: var(--ion-color-required, #666666);
    }
  }

  ion-datetime {
    width: 340px;
    min-width: unset;
    margin: 0px;

    --background: var(--ion-color-white, #ffffff);
    --background-rgb: var(--ion-color-primary-contrast-rgb);

    box-shadow: rgba(var(--ion-color-white), 0.3) 0px 10px 15px -3px;
  }

  .ctrl-btn-row {
    width: 100%;
    margin: 0px auto;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 12px;

    ion-button {
      flex-grow: 1;
      flex-basis: 0;
      height: 52px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      --border-radius: 4px;

      &.cancel-btn {
        --background: var(--ion-color-retake, #f2f2f2);
        --color: var(--ion-color-required, #666666);

        --color-activated: var(--ion-color-white, #ffffff);
        --background-activated: var(--ion-color-black, #000000);

        margin: 0px 4px 0px 0px;
      }

      &.submit-btn {
        --background: var(--ion-color-success, #3bbf41);
        color: var(--ion-color-white, #ffffff);
        margin: 0px 0px 0px 4px;
      }
    }
  }
}

.redirect-confirmation-wrapper {
  --width: 313px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 8px;

  .redirect-conf-header {
    width: 100%;
    margin: 0px auto;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 1px solid var(--ion-color-gray90);

    ion-icon {
      font-size: 24px;
      color: var(--ion-color-previous, #888888);
    }
  }

  .redirect-conf-content {
    width: 100%;
    margin: 0px auto;
    padding: 24px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      width: 100%;
      margin: 0px auto;
      font-weight: 300;
      padding: 4px 2px 0px 2px;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: var(--ion-color-black, #000000);
    }

    .footer {
      width: 100%;
      margin: 24px auto 0px auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;

      .ftr-btn {
        flex-grow: 1 !important;
        flex-basis: 0;
        height: 52px;
        flex: none;

        --border-radius: 4px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;

        &.cancel {
          --background: var(--ion-color-wild-sand, #f4f4f4);
          color: var(--ion-color-required, #666666);
        }

        &.submit {
          color: var(--ion-color-white, #ffffff);
          --background: var(--ion-color-cta);
          --background-activated: var(--ion-color-cta);
        }
      }
    }
  }
}

.payday-confirmation {
  --width: 313px;

  & > * {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px;
  }

  .content {
    img {
      width: 40px;
      height: 40px;
    }

    .title {
      width: 100%;
      margin: 16px auto 0px auto;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 19px;

      color: var(--ion-color-black, #000000);
    }

    .desc {
      width: 100%;
      margin: 0px;
      padding: 0px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: var(--ion-color-required, #666666);

      span {
        font-weight: 500;

        &:last-child {
          color: var(--ion-dark-green, #00a912);
        }
      }
    }

    .procceed-btn {
      width: 100%;
      margin: 8px auto;
      height: 52px;
      flex: none;

      --background: var(--ion-tos-highlight, #4799fb);
      --background-activated: var(--ion-tos-highlight, #4799fb);

      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: var(--ion-color-white, #ffffff);

      span {
        margin-left: 4px;
        color: var(--ion-color-dark-yellow, #ffc700);
        font-weight: 700;
      }
    }
  }

  .footnote {
    border-top: 0.5px solid var(--ion-color-input);

    &-desc {
      font-style: italic;
      padding-bottom: 0px;
      font-weight: 300;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: var(--ion-color-required, #666666);

      span {
        font-weight: 400;
        text-decoration-line: underline;
        color: var(--ion-color-cta, #5852e3);
      }
    }
  }
}

.payday-december-modal {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .devider {
    border: 0.5px solid #e0e0e0;
  }

  .disabled {
    background: #d6d6d630;

    .radio-item-label {
      opacity: 0.4;
    }
  }

  .modal-container {
    width: 100%;
    height: 100%;
    margin: 0px;
    box-sizing: border-box;

    &.banner {
      height: 540px;
    }

    .payday-dec-header {
      width: 100%;
      margin: 0px auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    .payday-dec-footer {
      width: 100%;
      margin: 0px auto;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .content {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;

      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      z-index: 1;

      &-header {
        width: 100%;
        margin: 0px auto;
        padding: 16px;

        .content-header-wrap {
          display: flex;
          flex-direction: column;
          gap: 8px;
          flex: none;
        }

        .content-header-btn {
          display: flex;
          justify-content: flex-end;

          height: 13px;

          .close-btn {
            width: 41px;
            height: 41px;
            flex: none;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 500px;
            border: 1px solid var(--ion-color-required);
            color: var(--ion-color-required, #666666);
            --background: transparent;
            --background-activated: transparent;
            font-size: 12px;

            ion-icon {
              font-size: 16px;
              flex: none;
            }
          }
        }

        img.santa {
          height: 29px;
        }

        .title {
          font-size: 23px;
          font-weight: 800;
          line-height: 16px;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;

          width: 100%;
          margin: 0px auto;
          text-align: center;
          color: var(--ion-color-black, #000000);
          margin-top: 23px;

          span {
            color: #c82020;
          }
        }

        .desc {
          width: 85%;
          margin: 7px auto;
          color: var(--ion-color-required, #666666);

          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }
      }

      &-dates {
        width: 100%;
        margin: 12px auto 0px auto;
        display: flex;
        gap: 12px;
        flex-wrap: wrap;
        padding: 16px;

        flex-grow: 1;

        .radio-group {
          padding: 12px 0px;
          width: 100%;
          margin: 0px auto;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: flex-start;
          gap: 8px;
          align-content: flex-start;

          .radio-item {
            --background: rgba(226, 237, 247, 0.4);
            --border-radius: 8px;
            --border-width: 0;
            --padding-start: 12px;
            --padding-end: 8px;
            --padding-top: 4px;
            --padding-bottom: 4px;
            max-height: 64px;
            height: 100%;
            width: calc(50% - 4px);
            margin: 0px;

            ion-radio {
              margin-right: 10px;
              --color: #4444444a;
              --color-checked: #00acff;
            }

            &-label {
              flex-grow: 1;
              display: flex;
              flex-direction: column;
              gap: 2px;
              padding: 12px 0;
              margin: 0;

              .day-name {
                font-weight: 600;
                font-size: 13px;
                line-height: 18px;
                text-align: left;
                color: var(--ion-color-black, #000000);
              }

              .date {
                font-weight: 400;
                font-size: 13px;
                line-height: 18px;
                text-align: left;
                color: var(--ion-color-required, #666666);
              }
            }
          }
        }
      }

      &-footer {
        width: 100%;
        margin: 0px auto;
        display: flex;
        flex-direction: column;
        gap: 2px;
        padding: 16px;

        ion-item.payday-conf {
          --padding-start: 0px;
          --padding-end: 0px;
          --inner-padding-end: 0px;
          --background: none;

          ion-checkbox {
            --background: transparent;

            margin-left: 0;
            margin-right: 12px;

            width: 15px;
            height: 15px;
          }

          .payday-conf-text {
            font-style: normal;
            font-weight: 300;
            font-size: 13px;
            line-height: 15px;
            color: var(--ion-color-grey, #333333);
          }
        }

        .set-december-payday {
          width: 100%;
          margin: 0px auto;
          flex: none;
          font-style: normal;
          font-weight: normal;
          --border-radius: 4px;
          height: 48px;
          font-size: 14px;
          line-height: 19px;
          --background: var(--ion-dark-green, #00a912);
          --background-activated: var(--ion-forest-green, #03a314);
          color: var(--ion-color-white, #ffffff);
        }
      }
    }
  }
}

.express-membership {
  --width: 313px;
  padding: 32px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;

  img {
    width: 60px;
    flex: none;
  }

  &-title {
    width: 100%;
    margin: 0px auto;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: var(--ion-color-black, #000000);
  }

  &-desc {
    width: 100%;
    margin: 0px auto;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    color: var(--ion-color-required, #666666);
  }

  .btn-col {
    width: 100%;
    margin: 12px auto 0px auto;
    display: flex;
    flex-direction: column;

    ion-button {
      width: 100%;
      margin: 0px auto;
      flex: none;
      font-style: normal;
      font-weight: normal;
      --border-radius: 4px;

      &.membership-details-btn {
        height: 48px;
        font-size: 14px;
        line-height: 19px;
        --background: var(--ion-color-cta, #5852e3);
        --background-activated: var(--ion-color-congrats, #303478);
        color: var(--ion-color-white, #ffffff);
      }

      &.slow-withdrawal-btn {
        font-size: 13px;
        line-height: 18px;
        text-decoration: underline;
        letter-spacing: 0.05;
        color: var(--ion-color-required, #666666);
      }
    }
  }
}

.cancel-membership {
  --width: 313px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-header {
    width: 100%;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 12px;
    border-bottom: 1px solid #e0e0e0;

    img {
      height: 16px;
      flex: none;
    }

    .close-btn {
      font-size: 11px;
      width: 36px;
      height: 36px;
      flex: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      border: 1px solid var(--ion-color-id-description, #aaaaaa);
      color: var(--ion-color-required, #666666);
      --background: transparent;
      --background-activated: transparent;

      ion-icon {
        font-size: 14px;
        flex: none;
      }
    }
  }

  &-content {
    width: 100%;
    margin: 0px auto;
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    .icon-cont {
      flex: none;
      width: 64px;
      height: 64px;
      border-radius: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(#e50300, 0.07);

      ion-icon {
        color: var(--ion-color-tip-error, #e50300);
        font-size: 32px;
      }
    }

    .title {
      width: 100%;
      margin: 0px auto;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      text-align: center;
      color: var(--ion-color-black, #000000);
    }

    .desc {
      width: 100%;
      margin: 0px auto;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      color: var(--ion-color-required, #666666);

      span {
        font-weight: 600;
        color: var(--ion-color-black, #000000);
      }
    }

    .confirm-btn {
      width: 100%;
      margin: 20px auto 0px auto;
      flex-basis: 0;
      height: 52px;
      flex: none;

      --border-radius: 4px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;

      color: var(--ion-color-white, #ffffff);
      --background: var(--ion-color-tip-error, #e50300);
      --background-activated: var(--ion-status-color-error, #ff0000);
    }
  }
}

.custom-tip-modal {
  --width: 313px;

  .custom-tip-form {
    width: 100%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    gap: 8px;

    & > * {
      padding: 16px;
      width: 100%;
      margin: 0px;
    }

    .header {
      border-bottom: 0.5px solid var(--ion-color-gray90);

      h5 {
        width: 100%;
        margin: 0px auto;
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        line-height: 21px;
        color: var(--ion-color-black, #000000);
        text-transform: capitalize;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding-bottom: 0px;

      .input-cont {
        width: 100%;
        margin: 0px auto;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .input-header {
          width: 100%;
          margin: 0px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 4px;

          .label {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            color: var(--ion-color-required, #666666);
          }

          .percentage {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
            color: var(--ion-dark-green, #00a912);
          }
        }

        ion-input {
          padding: 6px 12px !important;
          width: 100%;
          margin: auto;
          min-height: 50px;
          background: var(--ion-color-white, #ffffff);
          border-radius: 4px;
          border: 2px solid var(--ion-color-retake);
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          color: #263238;

          &:focus-within {
            border-color: var(--ion-color-cta);
          }
        }

        .error-message {
          font-size: 13px;
          font-weight: normal;
          line-height: 16px;
          color: var(--ion-color-red, #eb5757);
        }
      }

      .check-box-cont {
        width: 100%;
        margin: 0px auto;
        display: flex;
        justify-content: flex-start;
        gap: 16px;

        ion-checkbox {
          flex: none;
          margin-top: 2px;
        }

        p {
          flex-grow: 1;
          margin: 0px;
          padding: 0px;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 19px;
          color: var(--ion-color-required);
        }

        &.warning {
          ion-checkbox {
            --border-color: var(--ion-status-color-error, #ff0000);
            --checkbox-background-checked: var(
              --ion-status-color-error,
              #ff0000
            );
            --border-color-checked: var(--ion-status-color-error, #ff0000);
          }

          p {
            color: var(--ion-status-color-error, #ff0000);
          }
        }
      }
    }

    .footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;

      .ftr-btn {
        flex-grow: 1 !important;
        flex-basis: 0;
        height: 52px;
        flex: none;

        --border-radius: 4px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;

        &.cancel {
          --background: var(--ion-color-wild-sand, #f4f4f4);
          color: var(--ion-color-required, #666666);
        }

        &.submit {
          color: var(--ion-color-white, #ffffff);
          --background: var(--ion-color-congrats);
          --background-activated: var(--ion-color-congrats);
        }
      }
    }
  }
}

.give-tip-modal {
  .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    ion-img {
      width: 32px;
      flex: none;
    }

    h5 {
      width: auto !important;
      margin: 0;
      flex-grow: 1;
    }
  }

  .footer {
    .ftr-btn.submit {
      --background: var(--ion-color-grey, #333) !important;
      --background-activated: var(--ion-color-black, #000) !important;
    }
  }
}

.emp-code-input {
  --width: 313px;

  .header {
    width: 100%;
    margin: 0px auto;
    padding: 16px;

    display: flex;
    flex-direction: column;
    gap: 4px;

    border-bottom: 0.5px solid var(--ion-border-color);

    .title {
      width: 100%;
      margin: 0px auto;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 21px;
      text-align: left;
      color: var(--ion-color-black, #000000);
    }

    .desc {
      width: 100%;
      margin: 0px auto;
      font-style: normal;
      font-size: 13px;
      line-height: 18px;
      text-align: left;
      color: var(--ion-color-required, #666666);
    }
  }

  .emp-code-form {
    width: 100%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;

    .input-wrapper {
      width: 100%;
      margin: 0px;
      display: flex;
      flex-direction: column;

      ion-item {
        width: 100%;
        margin: 0px auto;
        padding: 0;
        --padding-start: 0px;
        --padding-end: 0px;
        --inner-padding-end: 0px;
        --inner-padding-start: 0px;
        --background: none;

        ion-input {
          width: 100%;
          margin: 0px auto;
          padding: 0;
          color: var(--ion-color-cta, #5852e3);

          font-size: 16px;
          line-height: 21px;
          font-weight: 600;

          --placeholder-color: var(--ion-color-text-cards);
          --placeholder-font-weight: 400;
        }
      }
    }
  }

  .footer {
    width: 100%;
    margin: 0px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 16px;
    border-top: 0.5px solid var(--ion-border-color);

    .ftr-btn {
      flex-grow: 1 !important;
      flex-basis: 0;
      height: 52px;

      --border-radius: 4px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;

      &.cancel {
        --background: var(--ion-color-wild-sand, #f4f4f4);
        color: var(--ion-color-required, #666666);
      }

      &.submit {
        color: var(--ion-color-white, #ffffff);
        --background: var(--ion-color-cta, #5852e3);
        --background-activated: var(--ion-color-black, #000);
      }
    }
  }
}

.upload-statement-confirmation {
  --width: 313px;
  --border-radius: 8px;

  margin: 0px;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    margin: auto;
    padding: 24px 16px 16px 16px;

    .header-text {
      width: 100%;
      margin: 0px auto;
      font-style: normal;
      font-weight: 900;
      font-size: 21px;
      line-height: 25px;
      text-align: left;
      color: var(--ion-color-black, #000000);
    }
  }

  .content {
    padding: 0px 16px 16px 16px;
    width: 100%;
    margin: auto;

    display: flex;
    flex-direction: column;

    .confirmation-text {
      width: 100%;
      margin: 0px auto 24px auto;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: var(--ion-color-black, #000000);
    }

    .statement {
      display: flex;
      align-items: center;
      width: 100%;
      margin: auto;

      img {
        width: 32px;
        height: 32px;

        margin-right: 12px;
      }

      .filename {
        width: 100%;
        margin: 0px auto;
        font-size: 16px;
        line-height: 22px;
        flex-grow: 1;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: var(--ion-color-radio-label, #444444);
      }
    }
  }

  .footer {
    width: 100%;
    margin: 8px auto auto auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    border-top: 0.5px solid #e0e0e0;

    .ftr-btn {
      flex-grow: 1;
      flex-basis: 0;
      --border-radius: 4px;
      min-height: 52px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;

      &.close-btn {
        margin-right: 4px;
      }

      &.confirm-btn {
        margin-left: 4px;
        --background: var(--ion-tos-highlight, #4799fb);
        color: var(--ion-color-white, #ffffff);
      }
    }
  }
}

ion-popover.lang-popover,
ion-popover.header-action-popover {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.15);
}

.lang-selection-cont {
  .lang-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    border: 0.5px solid var(--ion-border-color);

    &:last-child {
      border: none;
    }

    .lang-display {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--ion-stepper-prev);
      box-sizing: border-box;
      border-radius: 36px;
      padding: 4px 5px;

      img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }

      span {
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        color: var(--ion-color-id-description, #aaaaaa);
      }
    }

    .lang-name {
      flex-grow: 1;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      margin: 0px 16px;
      color: var(--ion-color-gray, #333333);
    }

    .current-lang {
      width: 6px;
      height: 6px;
      border-radius: 6px;
      background: var(--ion-color-cta, #5852e3);
    }
  }
}

.action-selection-cont {
  .action-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    border-bottom: 0.5px solid var(--ion-border-color);

    &:last-child {
      border: none;
    }

    &-name {
      flex-grow: 1;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      margin: 0px 16px;
      color: var(--ion-color-gray, #333333);

      &#delete {
        color: var(--ion-status-color-error, #ff0000);
      }
    }
  }
}

ion-popover.trust-content-wrapper,
ion-popover.download-links-wrapper,
ion-popover.insurance-reminder-wrapper {
  --width: 313px;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0);

  &::part(content) {
    top: unset !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 24px;
    width: 90%;
    max-width: 350px;
    margin: 0px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.02);
    transform: translateY(100%);
    transition: transform 0.2s ease-in-out;
    opacity: 0;
    scale: 0;
  }

  &.show {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.03);
    &::part(content) {
      transform: translateY(0%);
      opacity: 1;
      scale: 1;
    }
  }
}

ion-popover.insurance-reminder-wrapper {
  &::part(content) {
    bottom: 80px;
    width: 90%;
  }

  &.show {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.15);
  }
}

.trust-indicator-container {
  width: 100%;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;

  .trust-indicator {
    width: 40px;
    height: 40px;

    ion-img {
      width: 27px;
    }
  }
}

@media screen and (min-width: 420px) {
  .ion-page {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--ion-color-page, #f9f9f9);

    ion-content,
    ion-footer,
    ion-header {
      &.country-selector {
        max-width: 768px !important;
      }

      max-width: 350px !important;
      width: 100% !important;
      margin: 0px auto;
    }
  }
}

.cancel-withdraw {
  --color: #919090;
}

.referral-share-buttons .sb-group share-button {
  margin: 0 4px;

  button {
    font-size: 12px;
  }
}

// Filter modal
.close-filter-modal {
  width: 36px !important;
  height: 36px !important;
  border-radius: 500px;
  border: 1px solid var(--ion-color-required);

  display: flex;
  align-items: center;
  justify-content: center;

  flex: none;

  ion-icon {
    font-size: 16px !important;
    color: var(--ion-color-required, #666666);
  }
}

.filter-modal-wrapper {
  width: 100%;
  height: 100%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  padding: 12px 24px 0px 24px;

  .filter-form {
    width: 100%;
    height: 100%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .filter-section {
      width: 100%;
      margin: 0px auto;
      display: flex;
      flex-direction: column;
      gap: 12px;
      border-bottom: 1px solid var(--ion-color-gray90);
      padding: 12px 0px 16px 0px;

      &.no-border {
        border: none;
      }

      &:last-child {
        border: none;
      }

      &-header {
        width: 100%;
        margin: 0px auto 8px auto;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;

        color: var(--ion-color-black, #000000);
      }

      .select-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 100%;
        padding: 0;
        border: 1px solid var(--ion-color-footer, #d6d2d2);
        border-radius: 4px;

        --padding-start: 0 !important;
        --padding-end: 0 !important;
        --inner-padding-end: 0px !important;
        --inner-padding-start: 0px !important;

        ion-select {
          flex-grow: 1;
          width: 100%;
          --min-width: 100%;
          --width: 100%;
          padding: 6px 12px;

          &::part(text) {
            display: block;
            width: 100%;
            text-align: left;
          }

          &::part(icon) {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      .tag-container {
        width: 100%;
        margin: 0px auto;
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
      }

      .radio-group {
        width: 100%;
        margin: 0px auto;
        display: flex;
        flex-wrap: wrap;

        gap: 4px 16px;

        .radio-item {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          --padding-start: 0 !important;
          --padding-end: 0 !important;
          --inner-padding-end: 0px !important;
          --inner-padding-start: 0px !important;

          ion-radio {
            --color-checked: var(--ion-withdraw-rejection, #ef772e);
            margin-right: 6px;
          }

          .radio-label {
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            color: var(--ion-color-grey, #333333);
            white-space: nowrap !important;
          }
        }
      }
    }

    .btn-row {
      width: 100%;
      margin: auto auto 0px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 24px 0px;

      ion-button {
        flex-grow: 1;
        flex-basis: 0;
        height: 52px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        --border-radius: 4px;
        border-radius: 4px;

        &.clear-btn {
          --background: var(--ion-color-retake, #f2f2f2);
          --color: var(--ion-color-required, #666666);

          --color-activated: var(--ion-color-white, #ffffff);
          --background-activated: var(--ion-color-black, #000000);
        }

        &.submit-btn {
          --background: var(--ion-color-grey, #333);
          color: var(--ion-color-white, #ffffff);
        }
      }
    }
  }
}

.empty-list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0px auto;

  gap: 4px;
  max-width: 300px;
  padding-bottom: 24px;

  &-icon-cont {
    width: 100px;
    height: 100px;
    margin: 8px auto;
    border-radius: 500px;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;

    background: var(--ion-color-page, #f9f9f9);

    ion-icon {
      font-size: 50px;
      color: var(--ion-color-text-cards, #939393);
    }
  }

  &-title {
    width: 100%;
    text-align: center;
    margin: 0px auto;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: var(--ion-color-radio-label, #444444);
  }

  &-desc {
    width: 100%;
    text-align: center;
    margin: 0px auto;
    font-size: 14px;
    line-height: 19px;
    color: var(--ion-color-required, #666666);
  }
}

.read-count {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;

  ion-icon {
    font-size: 18px;
    color: var(--ion-withdraw-rejection, #ef772e);
  }

  .minutes {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: var(--ion-color-grey, #333333);
  }
}

.referral-banner-wrap {
  background: linear-gradient(
      0deg,
      rgba(239, 119, 46, 0.02),
      rgba(239, 119, 46, 0.02)
    ),
    #ffffff;
  border-radius: 4px;

  background-image: url(/assets/icon/referral-bg.svg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  display: flex;
  padding: 12px;
  margin: 23px 9px;
  min-height: 63px;
  gap: 12px 16px;
  align-items: center;
  justify-content: flex-end;

  &.wrap {
    flex-wrap: wrap;
  }

  .referral-icon {
    flex: none;
    height: 27px;
  }

  .referral-banner-content {
    flex-grow: 1;
    display: flex;
    gap: 0px 12px;
    align-items: center;
  }

  .referral-banner-text {
    flex-grow: 1;
    margin: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: var(--ion-color-radio-label, #444444);

    span {
      font-weight: 800;
      color: #00d417;
    }
  }

  .referral-banner-button {
    padding: 8px;
    min-width: 95px;
    min-height: 36px;

    background: linear-gradient(90deg, #ef772e -9.37%, #fba351 109.9%);
    border-radius: 4px;
    border: none;

    color: var(--ion-color-white, #ffffff);
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
  }
}

app-popover-ion-header {
  width: 100%;
  margin: 0px auto;
}

// Camera-video PWA - path
$camera-header-height: 4em;
$camera-footer-height: 9em;

pwa-camera-modal-instance {
  @keyframes move {
    0% {
      transform: translate(
        -50%,
        calc(-2.5em + calc(calc(100vw - 30px) * (205 / 320) / 2))
      );
    }

    50% {
      transform: translate(
        -50%,
        calc(-2.5em - calc(calc(100vw - 30px) * (205 / 320) / 2))
      );
    }

    100% {
      transform: translate(
        -50%,
        calc(-2.5em + calc(calc(100vw - 30px) * (205 / 320) / 2))
      );
    }
  }

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 400px;
    width: calc(100% - 30px);
    height: 4px;
    background: linear-gradient(
      to right,
      transparent 0%,
      #03da76 20%,
      #03da76 80%,
      transparent 100%
    );
    animation: move 2s ease-in-out infinite;
  }

  @media screen and (min-width: 600px) {
    @keyframes move {
      0% {
        transform: translate(-50%, calc(-2.5em + 132.625px));
      }

      50% {
        transform: translate(-50%, calc(-2.5em - 132.625px));
      }

      100% {
        transform: translate(-50%, calc(-2.5em + 132.625px));
      }
    }

    &::after {
      width: 400px;
    }
  }

  pwa-camera {
    .accept {
      z-index: 9999 !important;
    }
  }

  &.front {
    &::after,
    &::before {
      display: none !important;
      visibility: hidden !important;
    }
  }
}

pwa-camera-modal {
  z-index: 9998;
  max-width: 600px;
  position: absolute;
  top: $camera-header-height;
  left: 0px;
  right: 0px;
  height: calc(100% - ($camera-footer-height + $camera-header-height));

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 30px);
    height: calc(100vw * (205 / 320));

    background: linear-gradient(
          to right,
          var(--ion-color-white) 5px,
          transparent 5px
        )
        0 0,
      linear-gradient(to right, var(--ion-color-white) 5px, transparent 5px) 0
        100%,
      linear-gradient(to left, var(--ion-color-white) 5px, transparent 5px) 100%
        0,
      linear-gradient(to left, var(--ion-color-white) 5px, transparent 5px) 100%
        100%,
      linear-gradient(to bottom, var(--ion-color-white) 5px, transparent 5px) 0
        0,
      linear-gradient(to bottom, var(--ion-color-white) 5px, transparent 5px)
        100% 0,
      linear-gradient(to top, var(--ion-color-white) 5px, transparent 5px) 0
        100%,
      linear-gradient(to top, var(--ion-color-white) 5px, transparent 5px) 100%
        100%;

    background-repeat: no-repeat;
    background-size: 30px 30px;
    z-index: 9999;
  }

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 30px);
    height: calc(100vw * (205 / 320));
    box-shadow: 0px 0px 0px 2000px #000000aa;
  }

  @media screen and (min-width: 600px) {
    width: 600px;
    height: calc(605px - 13em);
    left: 50%;
    top: 50%;
    transform: translate(-50%, calc(-50% - 2.5em));

    &::before {
      width: 400px;
      height: 265.25px;
    }

    &::after {
      width: 400px;
      height: 265.25px;
    }
  }

  &.front {
    &::after,
    &::before {
      display: none !important;
      visibility: hidden !important;
    }
  }
}

.confirm-skip-id-card {
  --width: 287px;

  padding: 24px 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .header {
    width: 64px;
    height: 64px;
    border-radius: 500px;
    flex: none;
    margin: 16px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
        0deg,
        rgba(255, 188, 0, 0.07),
        rgba(255, 188, 0, 0.07)
      ),
      #ffffff;

    .warning-icon {
      font-size: 40px;
      color: var(--ion-status-color-warning, #ffcc3e);
    }
  }

  .body {
    .title {
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 24px;
      color: var(--ion-color-black, #000);
      text-align: center;
    }

    .description {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: var(--ion-color-required, #666666);
      text-align: center;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 24px auto auto auto;
    gap: 12px;

    ion-button {
      flex-grow: 1;
      flex-basis: 0;
      margin: 0px;
      height: 48px;
      box-sizing: border-box;
      border-radius: 4px;

      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      text-align: center;

      &.close {
        --background: var(--ion-color-retake, #f2f2f2);
        --color: var(--ion-color-grey, #333333);

        --background-activated: var(--ion-stepper-prev, #e9e9e9);
        --color-activated: var(--ion-color-black, #000000);
      }

      &.contact-us,
      &.action {
        --background: var(--ion-color-grey, #333333);
        --color: var(--ion-color-white, #ffffff);
        --background-activated: var(--ion-color-black, #000000);
      }
    }
  }
}

.app-new-version {
  position: absolute;
  z-index: 999999 !important;
}

ion-popover .popover-viewport {
  display: initial;
  position: initial;
  margin: initial;
  border: initial;
  background: initial;
  padding: initial;
  width: initial;
  height: initial;
  overflow: initial;
  inset: initial;
  color: initial;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

.custom-ion-header {
  --ion-background-box-shadow: none;
  box-shadow: none;

  ion-toolbar {
    --ion-background-box-shadow: none;
    border-style: none;
    box-shadow: none;

    ion-back-button {
      color: var(--ion-color-contact-title, #303478) !important;
      --color-focused: var(--ion-color-contact-title, #303478);
      --color-hover: var(--ion-color-contact-title, #303478);
    }
  }
}

.upcoming-charges-notification-white,
.upcoming-charges-notification-gradient {
  display: flex;
  width: auto;
  height: 71px;
  padding: 10px 12px;
  align-items: center;
  border-radius: 4px;

  min-height: 50px;
  padding: 10px 12px;
}

.upcoming-charges-notification-white {
  background: linear-gradient(
      0deg,
      rgba(255, 211, 102, 0.13) 0%,
      rgba(255, 211, 102, 0.13) 100%
    ),
    #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.upcoming-charges-notification-gradient {
  background-color: #f8f8f8;
  background: url("assets/img/clip.svg");
  background-size: cover;
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    background: #fff;
  }

  &:before {
    left: -1px;
    -webkit-clip-path: circle(16px at left);
    clip-path: circle(16px at left);
  }

  &:after {
    right: -1px;
    -webkit-clip-path: circle(16px at right);
    clip-path: circle(16px at right);
  }
}

.validation-error-message-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;

  .error-message {
    font-size: 12px;
    font-weight: normal;
    line-height: 15px;
    color: var(--ion-color-danger, #eb445a);
  }
}

/* 
IonCalendar2: Addresses the issue where month names were not visible in dark mode.
*/
.month-packer-item button {
  color: #333333 !important;
}

swiper-container {
  width: 100%;
  height: 100%;

  --swiper-pagination-color: var(--ion-color-cta, #5852e3);
  --swiper-pagination-bullet-size: 6px;
  --swiper-pagination-bullet-horizontal-gap: 2px;
}

swiper-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tip-overview-modal {
  --height: auto;
  align-items: flex-end;

  .modal-wrapper {
    border-radius: 20px 20px 0 0;
    position: absolute;
    bottom: 0;
  }

  .ion-page {
    position: relative;
    display: block;
    contain: content;
  }

  .content-wrapper {
    max-height: 80vh;
    overflow-y: auto;
  }
}

// contact us component styles
app-contact-us {
  width: 100%;
}

.sign-up-link {
  text-decoration: underline !important;
  color: currentColor;
}

.login-cta {
  text-decoration: underline !important;
  color: currentColor;
}

.ion-refresher-wrapper {
  .hydrated {
    background: white;
  }
}

.full-width-popover {
  --width: 90%;
  --max-width: 400px;
}

/* Fix the popover arrow positioning */
ion-popover.full-width-popover::part(content),
ion-popover.full-width-popover::part(arrow) {
  left: 50% !important;
  transform: translateX(-50%);
}

.w-full {
  width: 100%;
}

::ng-deep .wide-select .select-popover {
  --min-width: 300px !important;
  --width: 300px !important;
}

::ng-deep .popover-content {
  width: 300px !important;
  max-width: 300px !important;
}
