// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-ExtraBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/Inter-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "RobotoMono";
  src: url("../assets/fonts/RobotoMono-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "RobotoMono";
  src: url("../assets/fonts/RobotoMono-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/** Ionic CSS Variables **/
:root {
  --ion-font-family: "Inter", sans-serif;
  --ion-font-monotype: "RobotoMono", sans-serif;
  --ion-color-black: #000000;
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-grey98: #fafafa;
  --ion-color-gray90: #e5e5e5;
  --ion-color-storm-grey: #727a87;
  --ion-color-green: #3bbf41;
  --ion-color-malachite: #2cd865;
  --ion-color-insurance-green: #00d6a3;
  --ion-color-text-cards: #939393;
  --ion-color-required: #666666;
  --ion-color-required-dot: #5852e3;
  --ion-color-blue-magenta: #4844ad;
  --ion-color-radio-label: #444444;
  --ion-color-red: #eb5757;
  --ion-color-orange: #f2994a;
  --ion-color-dark-yellow: #ffc700;
  --ion-color-lavender: #efeefd;
  --ion-color-ghost-white: #f8f7fd;
  --ion-color-bright-gray: #eaeaea;

  --ion-next-btn-color: #f1fcf5;
  --ion-color-wild-sand: #f4f4f4;
  --ion-color-page: #f9f9f9;
  --ion-color-ghost-white: #f9f9fd;
  --ion-color-page-secondary: #fafafa;
  --ion-color-previous: #888888;
  --ion-color-whisper: #eeeeee;
  --ion-alert-background: #f6faff;
  --ion-withdraw-rejection: #ef772e;
  --ion-color-persimmon: #e35e0d;
  --ion-color-seashell: #fef9f5;
  --ion-color-rose-white: #fef3ee;
  --ion-color-time: #a6a7bd;

  --ion-color-contact-info: #8f8afa;
  --ion-color-contact-title: #303478;
  --ion-color-description: rgba(48, 52, 120, 0.75);
  --ion-info-widget-icon: rgba(134, 129, 254, 0.8);
  --ion-color-cta: #5852e3;
  --ion-color-cta-rgb: 88, 82, 227;
  --ion-color-congrats: #303478;
  --ion-color-tip-amount: #020674;
  --ion-color-tip-popular: #eaeaea;
  --ion-color-tip-info: #363636;
  --ion-color-no-skip-tip: #3a3a3a;
  --ion-color-ultramarine: #1b168f;
  --ion-color-tip-error: #e50300;
  --ion-color-tip-bar-active: #b8d3b9;

  --ion-color-footer: #d6d2d2;
  --ion-tos-highlight: #4799fb;

  --ion-color-country-code: #00a912;
  --ion-color-forestial: #017b32;
  --ion-color-input: #cccccc;
  --ion-color-retake: #f2f2f2;
  --ion-color-id-description: #aaaaaa;

  --ion-tab-background: #f3f3fd;
  --ion-info-alert-background: #eef4fc;
  --ion-dark-green: #00a912;
  --ion-forest-green: #03a314;
  --ion-disabled-date: #bdbdbd;
  --ion-notif-message-background: #ffff0020;
  --ion-color-brilliance-blue: #fdfcff;
  --ion-color-snow: #fff8f8;

  --ion-border-color: #dddddd;
  --ion-copy-btn-background: 71, 153, 251;
  --ion-stepper-prev: #e9e9e9;
  --ion-stepper-indicator: #dddddd;
  --ion-color-gray: #333333;
  --ion-color-id-skip: #f0f0f0;

  --ion-approved-statement-background: #f2fbf3;
  --ion-delete-btn-background: #fff8f8;

  --ion-checklist-background: rgba(246, 250, 255, 0.26);
  --ion-checklist-border-color: #eff6ff;

  --ion-error-list-background: rgba(255, 246, 246, 0.26);
  --ion-error-list-border-color: #ffefef;

  --ion-upload-alert-info-background: rgba(254, 100, 111, 0.06);
  --ion-upload-alert-info-background-blue: rgba(88, 82, 227, 0.04);
  --ion-line-chat-color: #41c50a;

  --ion-whatsapp-chat-bg: #0d9f16;
  --ion-whatsapp-chat-bg-dark: #0a7f12;

  --ion-fb-chat-color: #1877f2;
  --ion-fb-chat-bg: #3b5998;
  --ion-fb-chat-bg-dark: #2d4373;

  --ion-conf-header-background: rgba(255, 240, 173, 0.12);
  --ion-color-warning: #ffc700;
  --ion-color-reminder: #00acff;
  --ion-color-notice: #ff4800;

  --ion-call-icon-bg: #d6f2ff;
  --ion-line: #ededed;

  --ion-color-withdrawal-speed-title: #222222;
  --ion-color-withdrawal-speed-radio-checked: #fba00f;

  --ion-color-withdraw-amount-title: #484848;
  --ion-color-country-title: #43386b;

  --ion-color-metro-link: #d4edc7;
  --ion-color-metro-link-background: #f4fbf5;
  --ion-color-metro-link-border: #778d6c;

  --ion-color-cotton: #f5f5fd;
  --ion-color-quartz: #d3d3e9;

  /** Transactions status colors **/
  --ion-status-color-success: #00c74f;
  --ion-status-color-error: #ff0000;
  --ion-status-color-pending: #d79c05;
  --ion-status-color-warning: #ffcc3e;
  --ion-status-color-partial-success: #30007b;
  --ion-status-color-partial-error: #7b3000;
  --ion-status-color-accounting-error: #0084fe;

  /** passbook colors **/
  --ion-ayudhya-content-text: #4f4645;
  --ion-ayudhya-passbook-ftr-text: #da2128;

  --ion-bangkok-passbook-ftr-text: #a6d9ff;

  --ion-ktb-passbook-ftr-text: #bde3ff;

  --ion-ktb-passbook-gtb-text: #ebff00;
  --ion-questionnaire-note-header: #d87700;
  --ion-questionnaire-note-border: #f5f5f5;

  --ion-color-chardon: #fdf2eb;

  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #3bbf41;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66, 140, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme. Commented for now
   * -------------------------------------------
   */

  .ios body {
    // --ion-background-color: #000000;
    // --ion-background-color-rgb: 0,0,0;

    // --ion-text-color: #ffffff;
    // --ion-text-color-rgb: 255,255,255;

    // --ion-color-step-50: #0d0d0d;
    // --ion-color-step-100: #1a1a1a;
    // --ion-color-step-150: #262626;
    // --ion-color-step-200: #333333;
    // --ion-color-step-250: #404040;
    // --ion-color-step-300: #4d4d4d;
    // --ion-color-step-350: #595959;
    // --ion-color-step-400: #666666;
    // --ion-color-step-450: #737373;
    // --ion-color-step-500: #808080;
    // --ion-color-step-550: #8c8c8c;
    // --ion-color-step-600: #999999;
    // --ion-color-step-650: #a6a6a6;
    // --ion-color-step-700: #b3b3b3;
    // --ion-color-step-750: #bfbfbf;
    // --ion-color-step-800: #cccccc;
    // --ion-color-step-850: #d9d9d9;
    // --ion-color-step-900: #e6e6e6;
    // --ion-color-step-950: #f2f2f2;

    // --ion-item-background: #000000;

    // --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  /*
   * Material Design Dark Theme. Commented for now
   * -------------------------------------------
   */

  .md body {
    // --ion-background-color: #121212;
    // --ion-background-color-rgb: 18,18,18;

    // --ion-text-color: #ffffff;
    // --ion-text-color-rgb: 255,255,255;

    // --ion-border-color: #222222;

    // --ion-color-step-50: #1e1e1e;
    // --ion-color-step-100: #2a2a2a;
    // --ion-color-step-150: #363636;
    // --ion-color-step-200: #414141;
    // --ion-color-step-250: #4d4d4d;
    // --ion-color-step-300: #595959;
    // --ion-color-step-350: #656565;
    // --ion-color-step-400: #717171;
    // --ion-color-step-450: #7d7d7d;
    // --ion-color-step-500: #898989;
    // --ion-color-step-550: #949494;
    // --ion-color-step-600: #a0a0a0;
    // --ion-color-step-650: #acacac;
    // --ion-color-step-700: #b8b8b8;
    // --ion-color-step-750: #c4c4c4;
    // --ion-color-step-800: #d0d0d0;
    // --ion-color-step-850: #dbdbdb;
    // --ion-color-step-900: #e7e7e7;
    // --ion-color-step-950: #f3f3f3;

    // --ion-item-background: #1e1e1e;

    // --ion-toolbar-background: #1f1f1f;

    // --ion-tab-bar-background: #1f1f1f;

    // --ion-card-background: #1e1e1e;
  }
}
